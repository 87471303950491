<template>
  <div>
    <div class="modalPopup patch_note_modal">
      <div class="modal_header">
        <h3 class="title">업데이트 사항</h3>
        <button class="modal_close" @click="closeModal"></button>
      </div>
      <div class="modal_body">
        <div class="patch_note_title">
          <p>
            {{ newPatchNote.title }}
          </p>
          <p class="note_date">
            게시일 :
            {{ newPatchNote.create_time | formatDateNoHours }}
          </p>
        </div>
        <div class="patch_note_contents">
          <Viewer
            v-show="newPatchNote.content != undefined"
            :initialValue="newPatchNote.content"
          />
        </div>
      </div>
      <div class="modal_footer">
        <div v-if="!clickPatchNote" class="input_checkbox">
          <label
            for="checkbox2"
            class="chk_box"
            :class="neverSeeAgain == true ? 'active' : ''"
          >
            <i class="fa fa-check"></i>
          </label>
          <input
            type="checkbox"
            id="checkbox2"
            @click="neverSeeAgain = !neverSeeAgain"
          />
          <label for="checkbox2" class="label_txt">다시 보지 않기</label>
        </div>
        <button class="btn_sub2" @click="closeModal">
          확인
        </button>
      </div>
    </div>
    <div class="modal_layer"></div>
  </div>
</template>

<script>
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import FETCH_MIXIN from '@/mixins/fetch';
import { mapGetters } from 'vuex';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import Viewer from '@/layouts/components/Viewer.vue';

export default {
  mixins: [DRAG_MODAL_MIXIN, FETCH_MIXIN],
  components: {
    Viewer,
  },
  props: {
    clickPatchNote: {
      type: Boolean,
    },
  },
  data() {
    return {
      neverSeeAgain: false,
    };
  },
  computed: {
    ...mapGetters({
      newPatchNote: 'getNewPatchNoteFromPatchNote',
    }),
  },
  methods: {
    closeModal() {
      if (this.neverSeeAgain) {
        localStorage.setItem('patch_note_id', this.newPatchNote.id);
      }
      this.$emit('onclose');
    },
  },
  async created() {
    if (!this.clickPatchNote) {
      localStorage.removeItem('patch_note_id');
    }
  },
};
</script>

<style scoped></style>
