<template>
  <header id="header">
    <div class="head">
      <h1 class="logo">
        <router-link
          v-if="company_logo != null && !company_logo.includes('null')"
          v-show="showLogo"
          to="/"
          :style="
            company_logo != null
              ? `background: url(${company_logo}) no-repeat 50% 50%/contain;`
              : ''
          "
          >COLAB</router-link
        >
        <router-link v-else v-show="showLogo" to="/">COLAB</router-link>
      </h1>
      <div class="menu" @mouseleave="outsideHover()">
        <ul>
          <li
            v-for="(menuItem, index1) in menuItems"
            :key="menuItem.id"
            @mouseover="menuItem.menuVisible = true"
            @mouseleave="menuItem.menuVisible = false"
          >
            <a
              href="#"
              class="menu_link"
              @focusin="menuItem.menuVisible = true"
              @focusout="outsideClick(menuItem, index1)"
              >{{ menuItem.name }}</a
            >

            <ul v-show="menuItem.menuVisible">
              <li
                v-for="(subMenuItem, index) in menuItem.sub_menu"
                :key="subMenuItem.id"
                @click="menuItem.menuVisible = false"
                :class="{ device_setting: subMenuItem.name == '기기 설정' }"
              >
                <a
                  v-if="
                    subMenuItem.name == 'About Colab' ||
                      subMenuItem.name == 'About NEW ATLAN'
                  "
                  :href="
                    subMenuItem.name == 'About Colab'
                      ? 'http://colabintl.com'
                      : 'http://www.newatlan.co.kr'
                  "
                  target="_blank"
                  @blur="menuItem.menuVisible = false"
                  >{{ subMenuItem.name }}</a
                >
                <button
                  type="button"
                  v-else-if="subMenuItem.name == '로그아웃'"
                  @click="showLogoutModal = true"
                >
                  {{ subMenuItem.name }}
                </button>
                <router-link
                  v-else
                  v-show="
                    subMenuItem.name == '기기 설정'
                      ? $screen.width < 1280
                      : true
                  "
                  :to="
                    openedMenus.length < 22
                      ? subMenuItem.sub_detail
                      : openedMenus.find(x => x.path == subMenuItem.sub_detail)
                      ? subMenuItem.sub_detail
                      : ''
                  "
                  @blur.native="
                    menuItem.sub_menu.length - 1 == index
                      ? (menuItem.menuVisible = false)
                      : ''
                  "
                  >{{ subMenuItem.name }}</router-link
                >
              </li>
            </ul>
          </li>
          <!-- <li>
            <router-link to="/patch-note">패치노트관리</router-link>
          </li> -->
        </ul>
      </div>
      <button
        type="button"
        class="user"
        @click.prevent.stop="(logoutVisible = !logoutVisible), logoutClick()"
      >
        <span>{{ userId }}</span>
        <transition name="fade">
          <ul v-show="logoutVisible">
            <li class="logout">
              <a @click="ShowModal">로그아웃</a>
            </li>
          </ul>
        </transition>
      </button>
      <two-button-modal
        :modal_title="my_modal_title"
        :modal_content="my_modal_content"
        :isModalOpen="my_isModalOpen"
        @onclose="CloseModal"
        @OnYesClick="Logout"
      ></two-button-modal>
      <button class="nav_drawer">
        <span v-for="(n, index) in 3" :key="index"></span>
      </button>
    </div>
    <tab-bar></tab-bar>
    <two-button-modal
      :modal_title="'로그아웃'"
      :modal_content="'로그아웃 하시겠습니까?'"
      :isModalOpen="showLogoutModal"
      :index="-1"
      @onclose="showLogoutModal = false"
      @OnYesClick="
        Logout();
        showLogoutModal = false;
      "
    ></two-button-modal>
    <!-- <patch-note-page-modal
      v-show="patchNotePageOpen"
      @onclose="patchNotePageOpen = false"
    /> -->
    <patch-note-modal v-if="patchNoteOpen" @onclose="patchNoteOpen = false" />
  </header>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import TabBar from './TabBar';
import ModalMixin from '@/mixins/modal';
import fetchMixin from '@/mixins/fetch';
import SpinnerMixin from '@/mixins/spinner';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import PatchNoteModal from '@/layouts/components/PatchNoteModal.vue';
import { deleteCookie } from '@/utils/cookies.js';

export default {
  mixins: [ModalMixin, SpinnerMixin, fetchMixin],
  components: {
    TabBar,
    TwoButtonModal,
    PatchNoteModal,
  },
  data() {
    return {
      showLogoutModal: false,
      logoutVisible: false,
      deviceScreen: this.$screen.width,
      my_modal_title: '',
      my_modal_content: '',
      my_isModalOpen: false,
      showLogo: false,

      patchNoteOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      menuItems: 'getMenuItems',
      openedMenus: 'getOpenedMenus',
      userId: 'getUserId',
      users: 'getUsersTempFromUserPage',
      systemCompany: 'getSystemCompany',
      company_logo: 'getCompanyLogo',
    }),
  },
  methods: {
    outsideClick(menuItem, index1) {
      if (navigator.userAgent.match(/Android/i) == null) {
        if (menuItem != undefined) {
          document.onclick = $event => {
            $event = $event || window.event;
            $event.target.className == 'menu_link' &&
            this.menuItems[index1 - 1] == menuItem
              ? (menuItem.menuVisible = true)
              : (menuItem.menuVisible = false);
          };
          document.onkeydown = $event => {
            if ($event.keyCode == 27) {
              menuItem.menuVisible = false;
            }
          };
        }
      }
    },
    logoutClick() {
      if (this.logoutVisible) {
        document.onclick = $event => {
          $event = $event || window.event;
          $event.preventDefault();
          if ($event.target.className != 'user') {
            this.logoutVisible = false;
          }
        };
      }
    },
    outsideHover() {
      this.menuItems.forEach(element => {
        element.menuVisible = false;
      });
    },
    ...mapActions(['FETCH_ALL_MENUS']),
    ...mapMutations(['clearUser']),
    ShowModal() {
      this.my_modal_title = '로그아웃';
      this.my_modal_content = '로그아웃 하시겠습니까?';
      this.my_isModalOpen = true;
      this.logoutVisible = false;
    },
    CloseModal() {
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    deleteItem(e) {
      console.log(e);
    },
    async Logout() {
      deleteCookie('user');
      deleteCookie('auth');
      deleteCookie('saup');
      deleteCookie('CompanyUrl');
      localStorage.removeItem('companyUrl');
      localStorage.removeItem('token');
      const beforeSession =
        sessionStorage.getItem('session') != null
          ? JSON.parse(sessionStorage.getItem('session')).key
          : null;
      if (beforeSession != null) {
        this.$store.dispatch('DELETE_SESSION', beforeSession);
      }
      sessionStorage.removeItem('session');
      await this.$router.push('/login').catch(err => {
        console.log(err);
      });
      window.location.reload();
    },
    insertLogOutMenu() {
      if (this.menuItems.length < 1) {
        return;
      } else {
        const menuItemsTemp = this.lodash.clonedeep(this.menuItems);
        const systemMenus = menuItemsTemp[menuItemsTemp.length - 1];
        const findId = this.users.find(x => x.account == this.userId);
        if (systemMenus.sub_menu.filter(x => x.name == '로그아웃').length < 1) {
          systemMenus.sub_menu.splice(0, 0, {
            detail: null,
            id: 100000,
            name: '로그아웃',
            sub_detail: 'logout',
            sub_menu_id:
              findId.user_role_type_id == 1
                ? systemMenus.sub_menu[systemMenus.sub_menu.length - 3]
                    .sub_menu_id + 0.1
                : systemMenus.sub_menu[systemMenus.sub_menu.length - 1]
                    .sub_menu_id + 0.1,
          });
          this.$store.commit('setMenuItems', menuItemsTemp);
        }
      }
    },
  },

  async created() {
    if (this.company_logo == null) {
      await this.FETCH('FETCH_LOGO_IMAGE', '로고');
    }
    this.showLogo = true;
    if (this.systemCompany.id == undefined) {
      await this.FETCH('FETCH_SYSTEM_COMPANY', '사업자 정보');
    }

    await this.FETCH('FETCH_USER_ROLE_TYPE', '사용자 권한');
    if (this.users.length == 0) {
      await this.FETCH('FETCH_USER_LIST', '유저 목록');
    }

    if (this.menuItems.length == 0) {
      const findId = this.users.find(x => x.account == this.userId);
      if (findId.user_role_type_id == 1) {
        this.showSpinner();
        await this.$store
          .dispatch('FETCH_ALL_MENUS', this.userId)
          .then(() => {})
          .catch(error => {
            console.log('error', error);
            this.openOneButtonModal(
              '메뉴 로드 중 오류',
              '사용자 메뉴 리스트를 불러오는 중 오류 발생. 다시 로그인하여주십시오.',
            );
          });
      } else {
        this.showSpinner();
        await this.$store
          .dispatch('FETCH_ALL_USER_MENUS', findId.id)
          .then(() => {})
          .catch(error => {
            console.log('error', error);
            this.openOneButtonModal(
              '메뉴 로드 중 오류',
              '사용자 메뉴 리스트를 불러오는 중 오류 발생. 다시 로그인하여주십시오.',
            );
          });
      }
      this.hideSpinner();
    }
    this.insertLogOutMenu();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
