<template>
  <div id="wrapper">
    <app-header></app-header>
    <transition appear name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from '@/layouts/components/app-header/AppHeader';
import AppFooter from '@/layouts/components/AppFooter';
export default {
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>

<style scoped>
/* .fade-enter-active {
  transition: all 0.1s ease;
}
.fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
} */
</style>
