var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"favorite_menu"},[_c('div',{staticClass:"favorite_menu_head"},[_c('span',[_vm._v("즐겨찾기")]),_c('button',{on:{"click":function($event){return _vm.$emit('favorite-close')}}},[_c('img',{attrs:{"src":require("../../assets/images/icon/modal_close.png"),"alt":""}})])]),_c('ul',{class:{ active: _vm.filteredFavorList.length > 5 }},_vm._l((_vm.filteredFavorList),function(list){return _c('li',{key:list.id},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.openedMenus.length < 22
            ? _vm.routing(list.sub_detail)
            : _vm.openedMenus.find(function (x) { return x.path == list.sub_detail; })
            ? _vm.routing(list.sub_detail)
            : ''}}},[_vm._v(" "+_vm._s(list.name)+" ")]),_c('span',{staticClass:"btn_favorite on",on:{"click":function($event){return _vm.toggleFavorite(list)}}})])}),0),_c('div',{staticClass:"category"},_vm._l((_vm.favoriteList),function(menuItem,index){return _c('div',{key:menuItem.id,staticClass:"depth"},[_c('span',{staticClass:"category_name",attrs:{"id":("faid" + (menuItem.id))},on:{"click":function($event){return _vm.OpenTopMenu(menuItem.id)}}},[_vm._v(_vm._s(menuItem.name)),_c('i',{staticClass:"fa fa-angle-down"})]),_c('ul',_vm._l((_vm.checkAndDeleteMobileOnlyMenu(menuItem, index)),function(submenu){return _c('li',{key:submenu.sub_id,staticClass:"favor_submenus"},[(submenu.sub_detail != '/system/about')?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.openedMenus.length < 22
                ? _vm.routing(submenu.sub_detail)
                : _vm.openedMenus.find(function (x) { return x.path == submenu.sub_detail; })
                ? _vm.routing(submenu.sub_detail)
                : ''}}},[_vm._v(" "+_vm._s(submenu.name)+" ")]):_c('a',{attrs:{"href":submenu.name == 'About Colab'
                ? 'http://colabintl.com'
                : 'http://www.newatlan.co.kr',"target":"_blank"}},[_vm._v(" "+_vm._s(submenu.name)+" ")]),(_vm.$screen.width >= 1280 || submenu.name !== '기기 설정')?_c('span',{staticClass:"btn_favorite",class:{ on: submenu.state == 1 },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleFavorite(submenu)}}}):_vm._e()])}),0)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }