<template>
  <footer id="footer">
    <!-- <ul>
      <li><a @click.prevent="openTOSModal">이용약관</a></li>
      <li><a @click.prevent="openTOUPModal">개인정보취급방침</a></li>
    </ul> -->
    <a href="http://www.colabintl.com" target="_blank"
      ><span>Copyright © Co-LAB Intl., ALL RIGHTS RESERVED.</span></a
    >
    <!-- <a href="http://www.newatlan.co.kr" target="_blank"
      ><span>Copyright © NEW ATLAN., ALL RIGHTS RESERVED.</span></a
    > -->
    <!-- <terms-of-service
      v-if="isTOSModalOpen"
      @onclose="closeModal"
    ></terms-of-service>
    <terms-of-use-policy
      v-if="isTOUPModalOpen"
      @onclose="closeModal"
    ></terms-of-use-policy> -->
    <div
      class="modal_layer"
      v-if="isTOSModalOpen || isTOUPModalOpen"
      @click="closeModal"
    ></div>
  </footer>
</template>

<script>
// import TermsOfService from '@/layouts/components/TermsOfService';
// import TermsOfUsePolicy from '@/layouts/components/TermsOfUsePolicy';

export default {
  components: {
    // TermsOfService,
    // TermsOfUsePolicy,
  },
  data() {
    return {
      isTOSModalOpen: false,
      isTOUPModalOpen: false,
    };
  },
  methods: {
    openTOSModal() {
      this.isTOSModalOpen = true;
    },
    openTOUPModal() {
      this.isTOUPModalOpen = true;
    },
    closeModal() {
      this.isTOSModalOpen ? (this.isTOSModalOpen = false) : '';
      this.isTOUPModalOpen ? (this.isTOUPModalOpen = false) : '';
    },
  },
};
</script>

<style scoped></style>
