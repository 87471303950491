<template>
  <div class="search">
    <div class="input_search">
      <input
        type="text"
        :class="{ active: isActive }"
        placeholder="메뉴명 검색"
        :value="searchValue"
        @input="searchMenu($event.target.value)"
        @keydown.enter="OnClickSearchBtn"
      />
      <ul v-show="isActive">
        <li
          v-for="menuName in searchArray"
          :key="menuName.name"
          @click="selectMenu(menuName)"
        >
          <router-link
            :to="
              openedMenus.length < 22
                ? menuName.path
                : openedMenus.find(x => x.path == menuName.path)
                ? menuName.path
                : ''
            "
            >{{ menuName.name }}</router-link
          >
        </li>
      </ul>
      <button>
        <i class="fa fa-search" @click="OnClickSearchBtn"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      searchValue: '',
      searchArray: [],
      isActive: false,
    };
  },
  computed: {
    ...mapGetters({
      getMenuItems: 'getMenuItems',
      openedMenus: 'getOpenedMenus',
      menuNames: 'getSubMenus',
    }),
  },
  methods: {
    OnClickSearchBtn() {
      if (this.searchArray.length == 1) {
        this.searchValue = this.searchArray[0].name;
        this.$router.push(this.searchArray[0].path);
      }
    },
    searchMenu(newValue) {
      let menuFilter;

      if (this.$screen.width > 1280) {
        menuFilter = this.lodash
          .clonedeep(this.menuNames)
          .filter(x => x.path != '/system/setting');
      } else {
        menuFilter = this.lodash.clonedeep(this.menuNames);
      }

      this.searchValue = newValue;
      const Hangul = require('hangul-js');
      this.searchArray = menuFilter.filter(
        element =>
          element.name.includes(newValue.trim()) ||
          Hangul.d(element.name, true)
            .map(x => x[0])
            .join('')
            .includes(newValue.trim()),
      );
      if (this.searchArray.length == 0) {
        this.searchArray = [
          { name: '해당하는 메뉴가 존재하지 않습니다.', path: '' },
        ];
      } else if (menuFilter.length == this.searchArray.length) {
        this.isActive = false;
      } else if (
        this.searchArray.length == 1 &&
        this.searchArray[0].name == this.searchValue
      ) {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
    },
    selectMenu(menuNameChk) {
      if (menuNameChk.path != '') {
        this.searchValue = menuNameChk.name;
        this.isActive = false;
      }
    },
  },

  // watch: {
  //   searchValue(newValue) {
  //     this.searchArray = this.menuNames.filter(element =>
  //       element.name.includes(newValue.trim()),
  //     );
  //     if (
  //       this.menuNames.length == this.searchArray.length ||
  //       this.searchArray.length == 0
  //     ) {
  //       this.isActive = false;
  //     } else if (
  //       this.searchArray.length == 1 &&
  //       this.searchArray[0].name == this.searchValue
  //     ) {
  //       this.isActive = false;
  //     } else {
  //       this.isActive = true;
  //     }
  //   },
  // },
};
</script>

<style scoped></style>
