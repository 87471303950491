<template>
  <div class="head_sub">
    <draggable :list="openedMenus" class="navigation" dragoverBubble="false">
      <transition-group
        name="list-complete"
        mode="in-out"
        tag="div"
        type="transition"
      >
        <div
          v-for="(openedMenu, index) in openedMenus"
          :key="openedMenu.path"
          class="list-complete-item selected_item"
          :class="{ disabled: !openedMenu.selected }"
          @click="OpenMenu(openedMenu)"
          @touchstart="OpenMenu(openedMenu)"
        >
          <span>{{ $getPageTitle(openedMenu.path) }}</span>
          <button
            class="delete_item"
            @click="deleteItem(openedMenu, index)"
            @touchstart="deleteItem(openedMenu, index)"
          ></button>
        </div>
      </transition-group>
    </draggable>
    <!-- 즐겨 찾기 버튼 -->
    <div class="head_sub_r">
      <div class="favorite">
        <button id="favoriteButton" @click="favoriteVisible = !favoriteVisible">
          <span
            class="menu_favorite off"
            :class="favoriteVisible ? 'menu_favorite on' : 'menu_favorite off'"
          ></span>
        </button>
        <!-- 즐겨찾기 메뉴 -->
        <transition name="favorFade">
          <favorite-menu
            v-show="favoriteVisible"
            @favorite-close="favoriteVisible = false"
          ></favorite-menu>
        </transition>
      </div>
      <!-- 메뉴명 검색  -->
      <menu-search-box></menu-search-box>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import FavoriteMenu from '@/layouts/components/FavoriteMenu';
import MenuSearchBox from '@/layouts/components/MenuSearchBox';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import draggable from 'vuedraggable';

export default {
  mixins: [ModalMixin, SpinnerMixin],
  components: {
    FavoriteMenu,
    MenuSearchBox,
    draggable,
  },
  data() {
    return {
      favoriteVisible: false,
      selected_item: document.querySelector('.navigation .selected_item'),
    };
  },
  computed: {
    ...mapGetters({
      openedMenus: 'getOpenedMenus',
      user_action_types: 'getUserActionType',
    }),
    // eventRes() {
    //   // 사용 기기가 안드로이드 일 경우 터치 이벤트 아닐 경우 클릭 이벤트
    //   if (navigator.userAgent.match(/Android/i) != null) {
    //     console.log('touchstart');
    //     return 'touchstart';
    //   } else {
    //     console.log('click');
    //     return 'click';
    //   }
    // },
  },
  methods: {
    ...mapMutations(['addOpenedMenu', 'removeOpenedMenu']),
    OpenMenu(menuItem) {
      if (
        menuItem.selected ||
        this.openedMenus.findIndex(x => x.path == menuItem.path) < 0
      ) {
        return;
      }
      this.$router.push(menuItem.path);
    },
    deleteItem(openedMenu, index) {
      if (openedMenu.selected == true) {
        if (this.openedMenus.length == 1) {
          this.$router.push('/main');
        } else if (index == this.openedMenus.length - 1) {
          this.$router.push(this.openedMenus[index - 1].path);
        } else {
          this.$router.push(this.openedMenus[index + 1].path);
        }
      }
      this.removeOpenedMenu(index);
    },
    async fetch_user_action_type() {
      this.showSpinner();
      console.log(8);
      this.$store
        .dispatch('FETCH_USER_ACTION_TYPE')
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '오류',
            '유저 액션 정보 로드 중 오류. 다시 로그인해주십시오.',
          );
        });
      this.hideSpinner();
    },
  },
  mounted() {
    if (this.openedMenus.length > 0) {
      window.addEventListener('resize', () => {
        let findIndex = this.openedMenus.findIndex(x => x.title == '설정');
        if (window.screen.width > 1280 && findIndex != -1) {
          if (this.openedMenus.length == 1) {
            this.$router.push('/main');
          } else if (findIndex == this.openedMenus.length - 1) {
            this.$router.push(this.openedMenus[findIndex - 1].path);
          } else {
            this.$router.push(this.openedMenus[findIndex + 1].path);
          }
          this.removeOpenedMenu(findIndex);
        }
      });
    }
  },
  created() {
    if (this.user_action_types.length == 0) {
      this.fetch_user_action_type();
    }
  },
};
</script>

<style scope lang="scss">
// open tab
.list-complete-item {
  transition: all 0.5s;
}
.list-complete-enter,
.list-complete-leave-to {
  transform: scale(0);
  opacity: 0;
}
.list-complete-leave-active {
  position: absolute;
}

// favorite
.favorFade-enter-active,
.favorFade-leave-active {
  transition: opacity 0.2s;
}
.favorFade-enter,
.favorFade-leave-to {
  opacity: 0;
}
</style>
