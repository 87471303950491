var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{attrs:{"id":"header"}},[_c('div',{staticClass:"head"},[_c('h1',{staticClass:"logo"},[(_vm.company_logo != null && !_vm.company_logo.includes('null'))?_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLogo),expression:"showLogo"}],style:(_vm.company_logo != null
            ? ("background: url(" + _vm.company_logo + ") no-repeat 50% 50%/contain;")
            : ''),attrs:{"to":"/"}},[_vm._v("COLAB")]):_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLogo),expression:"showLogo"}],attrs:{"to":"/"}},[_vm._v("COLAB")])],1),_c('div',{staticClass:"menu",on:{"mouseleave":function($event){return _vm.outsideHover()}}},[_c('ul',_vm._l((_vm.menuItems),function(menuItem,index1){return _c('li',{key:menuItem.id,on:{"mouseover":function($event){menuItem.menuVisible = true},"mouseleave":function($event){menuItem.menuVisible = false}}},[_c('a',{staticClass:"menu_link",attrs:{"href":"#"},on:{"focusin":function($event){menuItem.menuVisible = true},"focusout":function($event){return _vm.outsideClick(menuItem, index1)}}},[_vm._v(_vm._s(menuItem.name))]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(menuItem.menuVisible),expression:"menuItem.menuVisible"}]},_vm._l((menuItem.sub_menu),function(subMenuItem,index){return _c('li',{key:subMenuItem.id,class:{ device_setting: subMenuItem.name == '기기 설정' },on:{"click":function($event){menuItem.menuVisible = false}}},[(
                  subMenuItem.name == 'About Colab' ||
                    subMenuItem.name == 'About NEW ATLAN'
                )?_c('a',{attrs:{"href":subMenuItem.name == 'About Colab'
                    ? 'http://colabintl.com'
                    : 'http://www.newatlan.co.kr',"target":"_blank"},on:{"blur":function($event){menuItem.menuVisible = false}}},[_vm._v(_vm._s(subMenuItem.name))]):(subMenuItem.name == '로그아웃')?_c('button',{attrs:{"type":"button"},on:{"click":function($event){_vm.showLogoutModal = true}}},[_vm._v(" "+_vm._s(subMenuItem.name)+" ")]):_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(
                  subMenuItem.name == '기기 설정'
                    ? _vm.$screen.width < 1280
                    : true
                ),expression:"\n                  subMenuItem.name == '기기 설정'\n                    ? $screen.width < 1280\n                    : true\n                "}],attrs:{"to":_vm.openedMenus.length < 22
                    ? subMenuItem.sub_detail
                    : _vm.openedMenus.find(function (x) { return x.path == subMenuItem.sub_detail; })
                    ? subMenuItem.sub_detail
                    : ''},nativeOn:{"blur":function($event){menuItem.sub_menu.length - 1 == index
                    ? (menuItem.menuVisible = false)
                    : ''}}},[_vm._v(_vm._s(subMenuItem.name))])],1)}),0)])}),0)]),_c('button',{staticClass:"user",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();(_vm.logoutVisible = !_vm.logoutVisible), _vm.logoutClick()}}},[_c('span',[_vm._v(_vm._s(_vm.userId))]),_c('transition',{attrs:{"name":"fade"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.logoutVisible),expression:"logoutVisible"}]},[_c('li',{staticClass:"logout"},[_c('a',{on:{"click":_vm.ShowModal}},[_vm._v("로그아웃")])])])])],1),_c('two-button-modal',{attrs:{"modal_title":_vm.my_modal_title,"modal_content":_vm.my_modal_content,"isModalOpen":_vm.my_isModalOpen},on:{"onclose":_vm.CloseModal,"OnYesClick":_vm.Logout}}),_c('button',{staticClass:"nav_drawer"},_vm._l((3),function(n,index){return _c('span',{key:index})}),0)],1),_c('tab-bar'),_c('two-button-modal',{attrs:{"modal_title":'로그아웃',"modal_content":'로그아웃 하시겠습니까?',"isModalOpen":_vm.showLogoutModal,"index":-1},on:{"onclose":function($event){_vm.showLogoutModal = false},"OnYesClick":function($event){_vm.Logout();
      _vm.showLogoutModal = false;}}}),(_vm.patchNoteOpen)?_c('patch-note-modal',{on:{"onclose":function($event){_vm.patchNoteOpen = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }