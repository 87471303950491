<template>
  <div class="favorite_menu">
    <div class="favorite_menu_head">
      <span>즐겨찾기</span
      ><button @click="$emit('favorite-close')">
        <img src="../../assets/images/icon/modal_close.png" alt="" />
      </button>
    </div>
    <ul :class="{ active: filteredFavorList.length > 5 }">
      <li v-for="list in filteredFavorList" :key="list.id">
        <a
          href="#"
          @click.prevent="
            openedMenus.length < 22
              ? routing(list.sub_detail)
              : openedMenus.find(x => x.path == list.sub_detail)
              ? routing(list.sub_detail)
              : ''
          "
        >
          {{ list.name }} </a
        ><span class="btn_favorite on" @click="toggleFavorite(list)"></span>
      </li>
    </ul>
    <div class="category">
      <div
        class="depth"
        v-for="(menuItem, index) in favoriteList"
        :key="menuItem.id"
      >
        <span
          class="category_name"
          @click="OpenTopMenu(menuItem.id)"
          :id="`faid${menuItem.id}`"
          >{{ menuItem.name }}<i class="fa fa-angle-down"></i
        ></span>
        <ul>
          <li
            class="favor_submenus"
            v-for="submenu in checkAndDeleteMobileOnlyMenu(menuItem, index)"
            :key="submenu.sub_id"
          >
            <a
              v-if="submenu.sub_detail != '/system/about'"
              href="#"
              @click.prevent="
                openedMenus.length < 22
                  ? routing(submenu.sub_detail)
                  : openedMenus.find(x => x.path == submenu.sub_detail)
                  ? routing(submenu.sub_detail)
                  : ''
              "
            >
              {{ submenu.name }}
            </a>
            <a
              v-else
              :href="
                submenu.name == 'About Colab'
                  ? 'http://colabintl.com'
                  : 'http://www.newatlan.co.kr'
              "
              target="_blank"
            >
              {{ submenu.name }}
            </a>
            <span
              v-if="$screen.width >= 1280 || submenu.name !== '기기 설정'"
              class="btn_favorite"
              :class="{ on: submenu.state == 1 }"
              @click.prevent.stop="toggleFavorite(submenu)"
            ></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [fetchMixin, FavoriteMixin],
  computed: {
    ...mapGetters({
      userId: 'getUserId',
      users: 'getUsersTempFromUserPage',
      openedMenus: 'getOpenedMenus',
    }),
    filteredFavorList() {
      if (this.favoriteList !== undefined && this.favoriteList.length > 0) {
        const filterList = this.lodash.clonedeep(this.favoriteList);
        const mapFilteList = filterList.map(x => x.sub_menu);
        const reduceFilterList = mapFilteList.reduce((a, b) => a.concat(b));
        const findStateList = reduceFilterList.filter(x => x.state == 1);
        return findStateList;
      } else {
        return '';
      }
    },
  },
  methods: {
    checkAndDeleteMobileOnlyMenu(menuItem, index) {
      if (this.favoriteList.length - 1 == index) {
        if (this.$screen.width >= 1280) {
          let Temp = this.lodash.clonedeep(menuItem);
          Temp.sub_menu.splice(Temp.sub_menu.length - 1, 1);
          return Temp.sub_menu;
        } else {
          return menuItem.sub_menu;
        }
      } else {
        return menuItem.sub_menu;
      }
    },
    toggleFavorite(target) {
      const findId = this.users.find(x => x.account == this.userId);
      target.user_id = findId.id;
      if (target.state == 1) {
        target.state = 0;
      } else {
        target.state = 1;
      }
      console.log('target', target);
      this.showSpinner();
      this.$store
        .dispatch('INSERT_FAVORITE_MENU', target)
        .then(() => {
          this.FETCH_FAVORITE_MENU();
        })
        .catch(error => {
          console.log('FAVORITE MENE ERROR : ', error);
          this.openOneButtonModal(
            '즐겨찾기 메뉴 불러오기 오류',
            '즐겨찾기 메뉴를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
          );
        });

      this.hideSpinner();
    },
    routing(routePath) {
      const routedPath = this.$router.currentRoute.path;
      if (routedPath !== routePath) {
        this.$router.push(routePath);
      }
    },
    OpenTopMenu(faid) {
      console.log(faid);
      const el = $(`#faid${faid}`);
      let i = $(el).siblings('ul');
      $('#header .category .depth ul').slideUp('fast');
      $('#header .category .depth .category_name').removeClass('active');
      i.is(':visible')
        ? ($(el)
            .siblings('ul')
            .slideUp('fast'),
          $(el).removeClass('active'))
        : ($(el)
            .siblings('ul')
            .slideDown('fast'),
          $(el).addClass('active'));
    },
  },
  async created() {
    if (this.users.length < 1) {
      console.log(2);
      await this.FETCH('FETCH_USER_LIST', '사용자');
    }
    if (this.favoriteList.length == 0) {
      const findId = this.users.find(x => x.account == this.userId);

      if (findId.user_role_type_id == 1) {
        if (this.$store.getters.getUsersFromUserPage.length == 0) {
          this.showSpinner();
          await this.$store
            .dispatch('FETCH_ALL_MENUS', this.userId)
            .then(() => {})
            .catch(error => {
              console.log('error', error);
              this.openOneButtonModal(
                '메뉴 로드 중 오류',
                '사용자 메뉴 리스트를 불러오는 중 오류 발생. 다시 로그인하여주십시오.',
              );
            });
        }
      } else {
        this.showSpinner();
        await this.$store
          .dispatch('FETCH_ALL_USER_MENUS', findId.id)
          .then(() => {})
          .catch(error => {
            console.log('error', error);
            this.openOneButtonModal(
              '메뉴 로드 중 오류',
              '사용자 메뉴 리스트를 불러오는 중 오류 발생. 다시 로그인하여주십시오.',
            );
          });
      }
      this.hideSpinner();
    }
  },
};
</script>

<style scoped>
.progress {
  overflow: hidden;
}
.favor_submenus {
  cursor: pointer;
}
</style>
